import React, {useEffect} from 'react';
import './AboutUs.css'; 
import PuneImage from '../assets/q1.webp'; // Import an appropriate image
import founder1 from '../assets/founder1.jpg'; // Import an appropriate image
import founder2 from '../assets/founder2.jpg'; // Import an appropriate image

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <section className="about-us-section">
      <div className="about-us-hero">
        <div className="hero-overlay">
          <h1 className="hero-title">About The Pune Properties</h1>
          <p className="hero-subtitle">Crafting Exceptional Living Spaces in Pune</p>
        </div>
      </div>
      <div className="atul-chordia-section">
        <div className="image-container">
          <img src={PuneImage} alt="Pune Properties" />
        </div>
        <div className="text-container">
          <h2>Transforming Pune, One Property at a Time</h2>
          <p>
          Welcome to The Pune Properties, your trusted partner in real estate consultancy. With years of expertise and a deep understanding of Pune’s dynamic property market, we are committed to helping our clients find their dream homes, lucrative investments, and ideal commercial spaces.
At The Pune Properties, we pride ourselves on offering personalized, transparent, and professional real estate services tailored to meet the unique needs of both buyers and sellers. Whether you are looking to purchase a new home, invest in real estate, or sell your property, we offer end-to-end solutions that ensure a seamless experience.
<br/><br/>Our team of dedicated consultants works closely with clients to provide insights into the latest market trends, legal guidance, and financial advice, ensuring every decision is informed and beneficial.
From luxury apartments and villas to commercial offices and retail spaces, The Pune Properties is your go-to partner for all your real estate needs in Pune.
<br/>Join us in finding the perfect property for you, and let's build a future together!<br/><br/>
Your vision, our expertise.
          </p>
        </div>
      </div>

       {/* Our Founders Section */}
       <div className="founders-section">
        <h2>Our Founders</h2>
        <div className="founders-container">
          <div className="glassmorphic-card founder-card" style={{ backgroundColor: '#5c3c7b' }}>
            <img src={founder1} alt="Deepak Kalkute" style={{
    height: 'auto', maxWidth: '100%', maxHeight: '40vh', objectFit: 'cover', 
  }}/>
            
            <p style={{ color: '#ffd105', display: 'block', marginTop: '0.5rem', marginLeft:'25px' }}>
              <b><b>Deepak Kalkute</b></b><br/>
              <b>Civil Engineer & Real Estate Consultant  <br/></b>
              Deepak Kalkute is a highly skilled civil engineer with a strong foundation in construction principles and urban development. With 5 years of hands-on experience as a real estate consultant, he has established himself as an expert in identifying valuable properties, understanding market trends, and providing strategic advice to clients. Deepak's expertise spans residential, commercial, and mixed-use developments, and his commitment to delivering client-centric solutions has earned him a trusted reputation in the industry. Passionate about shaping the future of real estate, he combines technical know-how with a deep understanding of market dynamics to help clients make informed investments.
            </p>
          </div>
          <div className="glassmorphic-card founder-card" style={{ backgroundColor: '#5c3c7b' }}>
            <img src={founder2} alt="Pradeep Dhavan" style={{
    height: 'auto', maxWidth: '100%', maxHeight: '40vh', objectFit: 'cover', 
  }}/>
            <p style={{ color: '#ffd105', display: 'block', marginTop: '0.5rem', marginLeft:'25px' }}>
            <b><b>Pradeep Dhavan</b></b><br/>
             <b> Ex-Army Professional & Real Estate Consultant  <br/></b>
              Pradeep Dhavan, an ex-army professional, brings a disciplined, strategic, and detail-oriented approach to the real estate industry. With 5 years of experience as a real estate consultant, he has developed a keen understanding of market trends, property valuations, and investment opportunities. His military background instilled in him the values of integrity, leadership, and perseverance, which he applies to every client interaction.
              Pradeep specializes in residential and commercial property transactions, offering clients well-rounded guidance and solutions tailored to their needs. His unique combination of discipline from the armed forces and expertise in real estate makes him a trusted advisor in the industry. Dedicated to helping clients achieve their property goals, Pradeep is known for his commitment to excellence and his ability to navigate the complexities of the market confidently.            </p>
          </div>
        </div>
      </div>

      <div className="container about-content" style={{maxWidth:'1350px', marginBottom:'60px'}}>
        <div className="glassmorphic-card" style={{ backgroundColor: '#ffd105' }}>
          <h2>Who We Are</h2>
          <p>
          The Pune Properties is a trusted name in Pune's real estate market, dedicated to offering unparalleled services in property consultancy. With a deep understanding of the local market and a commitment to client satisfaction, we guide you in finding the perfect home, investment opportunity, or commercial space tailored to your needs.          
          </p>
        </div>

        <div className="glassmorphic-card" style={{ backgroundColor: '#ffd105' }}>
          <h2>Our Vision</h2>
          <p>
          Our vision is to redefine real estate experiences in Pune by connecting clients with properties that combine modern design, comfort, and sustainability. We strive to be the preferred choice for homebuyers and investors, delivering homes that aren't just structures but a lifestyle that enhances everyday living.          
          </p>
        </div>

        <div className="glassmorphic-card" style={{ backgroundColor: '#ffd105' }}>
          <h2>Our Values</h2>
          <p>
          Integrity, transparency, and customer-centricity are at the heart of our values. We focus on building trustful relationships with our clients, offering expert guidance and insights throughout their real estate journey. Our commitment is to ensure each client feels informed, valued, and confident in their real estate decisions.          
          </p>
        </div>
      </div>

      <div className="glassmorphic-card" style={{ backgroundColor: '#ffd105' }}>
        <h2>Our Commitment to Pune</h2>
        <p>
        We are deeply committed to Pune and its vibrant community. Every development we create is thoughtfully designed with the people in mind, blending green spaces, recreational facilities, and modern amenities to promote a harmonious and balanced lifestyle. With a strong focus on sustainability, we continuously strive to integrate eco-friendly practices into all our projects, ensuring a greener, healthier future for generations to come.
        </p>
      </div>

      <div className="glassmorphic-card" style={{ backgroundColor: '#ffd105' }}>
        <h2>Discover Our Projects</h2>
        <p>
        Explore our exclusive portfolio of residential properties, where luxury meets comfort. Whether it's sleek, modern apartments in vibrant city hubs or tranquil villas nestled in nature’s embrace, we offer homes that cater to every lifestyle and aspiration. Join us in shaping the future of Pune, one exquisite home at a time, and experience the perfect blend of elegance, convenience, and serenity.
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
