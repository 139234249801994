import React from 'react';
import './Property12.css'; // Adjust the path if needed

// Importing images directly
import img1 from '../../assets/img1.jpg';
import img2 from '../../assets/img2.jpg';
import img3 from '../../assets/img3.jpg';

const Property3 = () => {
    const property = {
        title: "Highrise Hub",
        description: "Highrise Hub is a prestigious commercial property that redefines business spaces in Pune. Located in a prime area, this iconic high-rise offers cutting-edge office spaces with panoramic city views and flexible layouts to meet the evolving needs of modern enterprises. Designed for businesses that value both style and functionality, Highrise Hub boasts top-notch amenities, seamless connectivity, and a sophisticated environment to inspire productivity and growth. With state-of-the-art infrastructure, 24/7 security, and ample parking facilities, Highrise Hub is the ultimate destination for companies looking to establish a strong presence in one of Pune’s most sought-after commercial districts.",
        images: [img1, img2, img3],
        highlights: ["Iconic High-Rise", "Panoramic City Views", "Flexible Layouts", "24/7 Security", "Ample Parking"]
    };

    return (
        <div className="commercial-property-details">
            <h1 className="commercial-property-title">{property.title}</h1>
            <div className="commercial-carousel">
                <div className="commercial-carousel-images">
                    {property.images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`${property.title} - image ${index + 1}`}
                            className={`commercial-carousel-image ${index === 0 ? 'active' : ''}`}
                        />
                    ))}
                </div>
            </div>
            <div className="commercial-property-description">
                <h2>About the Property</h2>
                <p>{property.description}</p>
            </div>
            <div className="commercial-highlights-section">
                <h2>Property Highlights</h2>
                <div className="commercial-highlights-list">
                    {property.highlights.map((highlight, index) => (
                        <div key={index} className="commercial-highlight-card">
                            <p>{highlight}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="commercial-table-container">
                <h2>Specifications</h2>
                <table className="commercial-property-table">
                    <thead>
                        <tr>
                            <th>Specification</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Location</td>
                            <td>Pune</td>
                        </tr>
                        <tr>
                            <td>Area</td>
                            <td>6000 sq ft</td>
                        </tr>
                        <tr>
                            <td>Parking</td>
                            <td>Ample</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button className="commercial-download-brochure-btn">
                <a href={`/brochures/highrise_hub.pdf`} download>
                    Download Brochure
                </a>
            </button>
        </div>
    );
};

export default Property3;
