import React, {useEffect} from 'react';
import './PrivacyPolicy.css'; // Make sure to update this CSS file

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="privacy-policy-container">
            <h1>Privacy Policy</h1>
            <p>
                At <strong>The Pune Properties</strong>, we are committed to protecting your privacy and ensuring your personal information is handled responsibly. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you interact with our website and services.
            </p>

            <h2>1. Information We Collect</h2>
            <p>We may collect the following types of information:</p>
            <ul>
                <li><strong>Personal Information:</strong> This includes your name, email address, phone number, mailing address, and any other information you provide when you contact us or use our services.</li>
                <li><strong>Property Preferences:</strong> Information about your property preferences, such as type, size, budget, and location.</li>
                <li><strong>Usage Data:</strong> We may collect information about how you use our website, including your IP address, browser type, pages visited, and the time and date of your visit.</li>
            </ul>

            <h2>2. How We Use Your Information</h2>
            <p>We use the information we collect for various purposes, including:</p>
            <ul>
                <li>To provide and manage our services.</li>
                <li>To communicate with you regarding your inquiries and property interests.</li>
                <li>To send you updates, promotional materials, and newsletters (you may opt out at any time).</li>
                <li>To improve our website and services based on user feedback and usage patterns.</li>
                <li>To comply with legal obligations and protect our rights.</li>
            </ul>

            <h2>3. Disclosure of Your Information</h2>
            <p>We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:</p>
            <ul>
                <li><strong>Service Providers:</strong> We may engage third-party service providers to assist us in operating our website and conducting our business. These service providers are obligated to protect your information.</li>
                <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
                <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or asset sale, your information may be transferred. We will notify you before your information is transferred and becomes subject to a different privacy policy.</li>
            </ul>

            <h2>4. Data Security</h2>
            <p>We take reasonable precautions to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. While we strive to protect your information, we cannot guarantee its absolute security.</p>

            <h2>5. Your Rights</h2>
            <p>You have the right to:</p>
            <ul>
                <li>Access, update, or delete your personal information.</li>
                <li>Withdraw your consent for us to process your data at any time (where we rely on your consent).</li>
                <li>Opt-out of marketing communications.</li>
            </ul>
            <p>To exercise these rights, please contact us using the information provided below.</p>

            <h2>6. Cookies</h2>
            <p>Our website may use cookies and similar tracking technologies to enhance user experience. Cookies are small files stored on your device that help us improve our services and understand user behavior. You can choose to accept or decline cookies through your browser settings.</p>

            <h2>7. Changes to This Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. Any changes will be posted on this page, and we will notify you of significant changes via email or through a notice on our website. Your continued use of our services after changes are made constitutes your acceptance of the new Privacy Policy.</p>

            <h2>8. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy or our privacy practices, please contact us at:</p>
            <p>
                <strong>The Pune Properties</strong><br />
                Baner, Pune<br />
                Email: <a href="mailto:thepuneproperties2024@gmail.com">thepuneproperties2024@gmail.com</a><br />
                Phone: 844-623-1017
            </p>
            <p>Thank you for trusting <strong>The Pune Properties</strong> with your real estate needs. Your privacy is important to us!</p>
        </div>
    );
};

export default PrivacyPolicy;
