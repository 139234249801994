import React from 'react';
import './FifthSection.css';

// Import images for the grid
import img1 from '../assets/Sheth-Avalon.jpg';
import img2 from '../assets/Sheth-Zuri-E-Brochure-20.jpg';
import img3 from '../assets/sky-court-elevation-29312315.jpeg.jpg';
import img4 from '../assets/Luxury-Apartments-Pune.jpg';
import img5 from '../assets/raymond-realty-phase-i-images-for-elevation-of-colorplus-raymond-realty-phase-i-37322573.jpg';
import img6 from '../assets/q9.webp';
import img7 from '../assets/q7.webp';
import img8 from '../assets/q1.webp';
import img9 from '../assets/q2.webp';
import img10 from '../assets/q3.webp';

const FifthSection = () => {
  return (
    <section className="fifth-section">
      <div className="title">
        <h1>The Pune Properties - Discover Excellence</h1>
      </div>
      <div className="image-grid">
        <div className="grid-item">
          <img src={img1} alt="Real Estate 1" />
        </div>
        <div className="grid-item">
          <img src={img2} alt="Real Estate 2" />
        </div>
        <div className="grid-item">
          <img src={img3} alt="Real Estate 3" />
        </div>
        <div className="grid-item">
          <img src={img4} alt="Real Estate 4" />
        </div>
        <div className="grid-item">
          <img src={img5} alt="Real Estate 5" />
        </div>
        <div className="grid-item">
          <img src={img6} alt="Real Estate 6" />
        </div>
        <div className="grid-item">
          <img src={img7} alt="Real Estate 7" />
        </div>
        <div className="grid-item">
          <img src={img8} alt="Real Estate 8" />
        </div>
        <div className="grid-item">
          <img src={img9} alt="Real Estate 9" />
        </div>
        <div className="grid-item">
          <img src={img10} alt="Real Estate 10" />
        </div>
      </div>
    </section>
  );
};

export default FifthSection;
