import React, { useState, useEffect } from 'react';
import './Property1.css';

// Import images
import img1 from './PropertyImages/11/WhatsApp Image 2024-10-13 at 3.37.15 PM (1).jpeg'
import img2 from './PropertyImages/11/WhatsApp Image 2024-10-13 at 3.37.15 PM (2).jpeg'
import img3 from './PropertyImages/11/WhatsApp Image 2024-10-13 at 3.37.15 PM.jpeg'

const images = [img1, img2, img3]; // Array of image imports

const PropertyDetails = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-slide functionality every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex + 1) % images.length
    );
  };
  const downloadBrochure = () => {
    const link = document.createElement('a');
    link.href = require('../../assets/Residential11.pdf').default; // Update this path accordingly
    link.setAttribute('download', 'Residential11.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="property-details">
      {/* Carousel Container */}
      <div className="carousel">
        <button className="carousel-button left" onClick={goToPrevious}>
          &#8249;
        </button>
        <div className="carousel-images">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index + 1}`}
              className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
            />
          ))}
        </div>
        <button className="carousel-button right" onClick={goToNext}>
          &#8250;
        </button>
      </div>

      {/* Property Title */}
      <h1 className="property-title">Rohan Ekam</h1>

      {/* Marquee Section */}
      <div className="marquee">
        <div className="marquee-content">
          Experience luxury living at Rohan Ekam, where modern design meets convenient accessibility in Balewadi, Pune.
        </div>
      </div>

      {/* Property Description Section */}
      <div className="property-description">
        <h2>Property Description</h2>
        <p>
          Rohan Ekam is a luxury residential project in Balewadi, Pune, spread across a vast 12.9-acre land parcel. It features nine towers, each soaring 32 stories high, offering spectacular views of the surrounding landscape. The project offers spacious 2, 3, and 4 BHK apartments, designed with Rohan's signature PLUS design philosophy, which emphasizes perfect ventilation, abundant natural light, privacy, and intelligent space planning. Amenities include a multipurpose hall, indoor games, gymnasium, lap pool, kids pool, meditation spaces, and outdoor fitness stations. Located close to key city hubs, schools, hospitals, and malls, Rohan Ekam combines modern living with convenient accessibility.
        </p>
      </div>

      {/* Overview Section */}
      <div className="overview-section">
        <h2>Project Overview</h2>
        <div className="overview-list">
          <div className="overview-card">
            <div className="overview-icon">🏗️</div>
            <p>Spacious Development: Covers 12.9 acres with 9 towers, each 32 stories high.</p>
          </div>
          <div className="overview-card">
            <div className="overview-icon">🏠</div>
            <p>Variety of Units: Offers 2, 3, and 4 BHK apartments to suit diverse needs.</p>
          </div>
          <div className="overview-card">
            <div className="overview-icon">💡</div>
            <p>PLUS Design Philosophy: Ensures optimal ventilation and natural light in every home.</p>
          </div>
          <div className="overview-card">
            <div className="overview-icon">🏊</div>
            <p>Extensive Amenities: Features 30+ amenities, including a lap pool, fitness center, and landscaped gardens.</p>
          </div>
          <div className="overview-card">
            <div className="overview-icon">📍</div>
            <p>Prime Location: Centrally located in Balewadi.</p>
          </div>
          <div className="overview-card">
            <div className="overview-icon">🌱</div>
            <p>Eco-Friendly Initiatives: Incorporates rainwater harvesting and sustainable practices.</p>
          </div>
          <div className="overview-card">
            <div className="overview-icon">⚽</div>
            <p>Recreational Areas: Includes a multi-purpose hall and sports facilities for an active lifestyle.</p>
          </div>
          <div className="overview-card">
            <div className="overview-icon">🔒</div>
            <p>Enhanced Security: Equipped with CCTV and smart access systems for resident safety.</p>
          </div>
        </div>
      </div>
      {/* Property Table */}
      <div className="table-container">
        <table className="property-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Size</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2 BHK</td>
              <td>869 sq ft</td>
            </tr>
            <tr>
              <td>3 BHK</td>
              <td>1306 sq ft, 1381 sq ft, 1391 sq ft

              </td>
            </tr>
            <tr>
              <td>4 BHK</td>
              <td>1844 sq ft, 2158 sq ft
              </td>
            </tr>
          </tbody>
        </table>
      </div>


      {/* Download Brochure Button */}
      <button className="download-brochure-btn" onClick={downloadBrochure}>
        Download Brochure
      </button>
    </div>
  );
};

export default PropertyDetails;
