// Footer.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h2>About Us</h2>
          <p>
            At The Pune Properties, we are a leading real estate consultancy in Baner, dedicated to helping you find your dream home, ideal investment, or commercial space. With expert market knowledge, we connect you with the best properties from top builders.
          </p>
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61566541717340" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.linkedin.com/company/104809129/admin/dashboard/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a href="https://www.instagram.com/thepuneproperties/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>

        <div className="footer-section">
          <h2>Quick Links</h2>
          <ul>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/residential">Residential</Link></li>
            <li><Link to="/commercial">Commercial</Link></li>
            <li><Link to="/partnership">Partners</Link></li>
            <li><Link to="/testimonials">Testimonials</Link></li>
          </ul>
        </div>

        <div className="footer-section">
          <h2>Other Links</h2>
          <ul>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><Link to="/terms&conditions">Terms & Conditions</Link></li>
            <li><Link to="/privacypolicy">Privacy Policy</Link></li>
            <li><Link to="/disclaimer">Disclaimer</Link></li>
          </ul>
        </div>

        <div className="footer-section">
          <h2>Contact Us</h2>
          <p>
            CORPORATE OFFICE: 26/19, Shambhu Vihar Society, Balaji Park, Baner, Pune, Maharashtra 411007
          </p>
          <div className="footer-contact">
            <i className="fas fa-phone-alt"></i> 844-623-1017<br />
            <i className="fas fa-envelope"></i> thepuneproperties2024@gmail.com<br />
          </div>
        </div>
      </div>
      <div className="footer-bottom" style={{ color: '#ffd105', textAlign: 'center', padding: '20px 0' }}>
        <p>© The Pune Properties. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
