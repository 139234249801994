import React, { useState, useEffect } from 'react';
import './Property1.css';

// Import images
import img1 from './PropertyImages/10/WhatsApp Image 2024-10-13 at 3.27.55 PM.jpeg'
import img2 from './PropertyImages/10/WhatsApp Image 2024-10-13 at 3.27.56 PM.jpeg'
import img3 from './PropertyImages/10/WhatsApp Image 2024-10-13 at 3.27.58 PM.jpeg'

const images = [img1, img2, img3]; // Array of image imports

const PropertyDetails = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-slide functionality every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex + 1) % images.length
    );
  };
  const downloadBrochure = () => {
    const link = document.createElement('a');
    link.href = require('../../assets/Residential10.pdf').default; // Update this path accordingly
    link.setAttribute('download', 'Residential10.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="property-details">
      {/* Carousel Container */}
      <div className="carousel">
        <button className="carousel-button left" onClick={goToPrevious}>
          &#8249;
        </button>
        <div className="carousel-images">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index + 1}`}
              className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
            />
          ))}
        </div>
        <button className="carousel-button right" onClick={goToNext}>
          &#8250;
        </button>
      </div>

      {/* Property Title */}
      <h1 className="property-title">Majestique Landmarks - The Ornate</h1>

      {/* Marquee Section */}
      <div className="marquee">
        <div className="marquee-content">
          Experience a grand lifestyle at The Ornate, where luxury and convenience meet in the heart of Baner, Pune.
        </div>
      </div>

      {/* Property Description Section */}
      <div className="property-description">
        <h2>Property Description</h2>
        <p>
          If life is all about creating the grandest of experiences, you've arrived at the perfect destination. Nestled amidst 3.25 acres of magnificence in the heart of Baner, this multi-dimensional abode reflects richness and style. The twin tower project is placed strategically to be in the vicinity of major employment hubs, world-class educational institutions, and all the other places of interest. Now, get to enjoy a lifestyle backed by ultimate convenience and satisfaction.
        </p>
      </div>

      {/* Highlights Section */}
      <div className="highlights-section">
        <h2>Project Highlights</h2>
        <div className="highlights-list">
          <div className="highlight-card">
            <div className="highlight-icon">📍</div>
            <p>Prime locality in Baner, Pune.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏢</div>
            <p>3, 3.5, 4, and 5 BHK luxury apartments.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏙️</div>
            <p>Two towers, each with 32 floors.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🚪</div>
            <p>Grand Entrance welcoming you into a luxurious lifestyle.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">✨</div>
            <p>Luxury amenities offering ultimate comfort and indulgence.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🌿</div>
            <p>Eco-friendly features for sustainable living.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🛡️</div>
            <p>Safety: Earthquake-resistant structure, CCTV surveillance, smart door access, and video door phones.</p>
          </div>
        </div>
      </div>
      {/* Property Table */}
      <div className="table-container">
        <table className="property-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Size</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3 BHK</td>
              <td>1141 sq ft, 1260 sq ft
              </td>
            </tr>
            <tr>
              <td>3.5 BHK</td>
              <td>1419 sq ft

              </td>
            </tr>
            <tr>
              <td>4 BHK</td>
              <td>1680 sq ft, 1841 sq ft
              </td>
            </tr>
          </tbody>
        </table>
      </div>


      {/* Download Brochure Button */}
      <button className="download-brochure-btn" onClick={downloadBrochure}>
        Download Brochure
      </button>
    </div>
  );
};

export default PropertyDetails;
