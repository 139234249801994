import React, { useState } from 'react';
import EnquiryForm from './EnquiryForm';
import './EnquireButton.css';

const EnquireButton = () => {
  const [showForm, setShowForm] = useState(false);

  const openForm = () => setShowForm(true);
  const closeForm = () => setShowForm(false);

  return (
    <>
      <a href="#enquire" className="enquire-now-btn" onClick={openForm}>
        ENQUIRE NOW
      </a>
      {showForm && <EnquiryForm closeModal={closeForm} />}
    </>
  );
};

export default EnquireButton;
