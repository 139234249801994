import React, {useEffect} from 'react';
import './Disclaimer.css'; // Import your CSS file

export const Disclaimer = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="disclaimer-container">
            <h1>Disclaimer</h1>
            <p>Welcome to The Pune Properties. The information provided on this website and through our services is for general informational purposes only. While we strive to provide accurate and up-to-date information, we make no guarantees regarding the completeness, reliability, or suitability of the information presented.</p>

            <h2>1. No Professional Advice</h2>
            <p>The content on this website should not be construed as professional advice. You should consult with a qualified professional before making any decisions based on the information provided.</p>

            <h2>2. Accuracy of Information</h2>
            <p>We endeavor to ensure that all information is accurate and up-to-date; however, we cannot guarantee the accuracy or completeness of the information. The Pune Properties is not responsible for any inaccuracies or omissions, or for the results obtained from the use of this information.</p>

            <h2>3. Property Listings</h2>
            <p>Property listings and descriptions are provided by property owners and other third-party sources. We do not guarantee the accuracy, reliability, or availability of any property information. Potential buyers or renters should verify property details independently.</p>

            <h2>4. Limitation of Liability</h2>
            <p>In no event shall The Pune Properties, its owners, employees, or affiliates be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of this website or services.</p>

            <h2>5. External Links</h2>
            <p>Our website may contain links to external sites that are not provided or maintained by The Pune Properties. We have no control over the content, accuracy, or availability of these sites. Inclusion of any linked site does not imply endorsement by us of the views expressed within them.</p>

            <h2>6. Changes to the Disclaimer</h2>
            <p>We may update this Disclaimer from time to time. We will notify users of any significant changes through our website. Your continued use of our services following any changes signifies acceptance of the updated Disclaimer.</p>

            <h2>7. Contact Us</h2>
            <p>If you have any questions or concerns regarding this Disclaimer, please contact us at:</p>
            <p>The Pune Properties<br />Baner, Pune</p>
        </div>
    );
};

export default Disclaimer;