import React, { useState, useEffect } from 'react';
import './TestimonialCarousel.css';

const testimonials = [
    { quote: "The Pune Properties made my dream home a reality. Their team was exceptional!", client: "Anjali S." },
    { quote: "The service I received was top-notch. Highly recommend The Pune Properties!", client: "Rahul M." },
    { quote: "The Pune Properties has the best listings in the city! I found my perfect home.", client: "Sneha P." },
    { quote: "Professional, knowledgeable, and dedicated. They truly care about their clients!", client: "Karan D." },
    { quote: "A seamless experience from start to finish. I'm so glad I chose The Pune Properties!", client: "Priya R." },
    { quote: "The attention to detail and customer service is unmatched. Highly satisfied!", client: "Vikram T." },
    { quote: "The Pune Properties helped me navigate the market effortlessly. Great job!", client: "Meera K." },
    { quote: "I can't thank Pune Properties enough for their support in finding my new home!", client: "Suresh N." }
];

const TestimonialCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            (prevIndex - 1 + testimonials.length) % testimonials.length
        );
    };

    useEffect(() => {
        const interval = setInterval(nextSlide, 5000); // Auto-slide every 5 seconds
        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    // Get 3 testimonials with wrapping
    const displayedTestimonials = [
        testimonials[currentIndex],
        testimonials[(currentIndex + 1) % testimonials.length],
        testimonials[(currentIndex + 2) % testimonials.length]
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <section className="testimonial-section">
            <div className="testimonial-title">
                <h2>What Our Clients Say</h2>
            </div>
            <div className="testimonial-carousel">
                <button className="carousel-control prev" onClick={prevSlide}>❮</button>
                <div className="testimonial-slider">
                    {displayedTestimonials.map((testimonial, index) => (
                        <div className="testimonial-slide" key={index}>
                            <p>"{testimonial.quote}"</p>
                            <h4>- {testimonial.client}</h4>
                        </div>
                    ))}
                </div>
                <button className="carousel-control next" onClick={nextSlide}>❯</button>
            </div>
        </section>
    );
};

export default TestimonialCarousel;
