import React, { useState, useEffect } from 'react';
import './Property1.css';

// Import images
import img1 from './PropertyImages/8/WhatsApp Image 2024-10-13 at 3.01.13 PM.jpeg'
import img2 from './PropertyImages/8/WhatsApp Image 2024-10-13 at 3.01.16 PM.jpeg'
import img3 from './PropertyImages/8/WhatsApp Image 2024-10-13 at 3.01.19 PM (2).jpeg'

const images = [img1, img2, img3]; // Array of image imports

const PropertyDetails = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-slide functionality every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex + 1) % images.length
    );
  };
  const downloadBrochure = () => {
    const link = document.createElement('a');
    link.href = require('../../assets/Residential8.pdf').default; // Update this path accordingly
    link.setAttribute('download', 'Residential8.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="property-details">
      {/* Carousel Container */}
      <div className="carousel">
        <button className="carousel-button left" onClick={goToPrevious}>
          &#8249;
        </button>
        <div className="carousel-images">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index + 1}`}
              className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
            />
          ))}
        </div>
        <button className="carousel-button right" onClick={goToNext}>
          &#8250;
        </button>
      </div>

      {/* Property Title */}
      <h1 className="property-title">Kasturi - The Balmoral Hillside</h1>

      {/* Marquee Section */}
      <div className="marquee">
        <div className="marquee-content">
          Experience the serene luxury of hillside living at The Balmoral Hillside – where modern comfort meets natural beauty.
        </div>
      </div>

      {/* Property Description Section */}
      <div className="property-description">
        <h2>Property Description</h2>
        <p>
          Imagine waking up to a scenic hill view. Imagine the first sip of coffee while the crisp morning breeze caresses you. Imagine an invigorating morning routine or a leisurely stroll amidst the hills. Imagine a life meticulously woven around the hillside. Now live it. 
          The suburbs of Baner & Balewadi are a touchstone of cosmopolitan living in the city. It embodies a fine contrast of posh residential, and commercial landmarks, from malls, international schools, and energetic main streets, to The Balmoral lineage of luxury residences.
        </p>
      </div>

      {/* Highlights Section */}
      <div className="highlights-section">
        <h2>Project Highlights</h2>
        <div className="highlights-list">
          <div className="highlight-card">
            <div className="highlight-icon">🔥</div>
            <p>Fire Fighting system with sprinklers and smoke detectors in each home</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🗑️</div>
            <p>Garbage chute on each floor</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">💡</div>
            <p>BMS system and control room</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏗️</div>
            <p>High-quality aluminum formwork</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🛠️</div>
            <p>Sleek & offset-free structural design</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🚿</div>
            <p>Non-corrosive 3-layer plumbing system</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🔌</div>
            <p>FRLS electrical wiring</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🚪</div>
            <p>High-speed elevators with LANDIC system (Battery powered automatic landing operation)</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🔒</div>
            <p>3-tier security & Automatic biometric main door lock</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">📹</div>
            <p>Smart Video Door Phones with access control for tower entrances</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🆘</div>
            <p>SOS function</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">📷</div>
            <p>CCTV surveillance system</p>
          </div>
        </div>
      </div>
      {/* Property Table */}
      <div className="table-container">
        <table className="property-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Size</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>4.5 BHK</td>
              <td>2486 sq ft</td>
            </tr>
            <tr>
              <td>4.5 BHK</td>
              <td>2498 sq ft</td>
            </tr>
            <tr>
              <td>4.5 BHK</td>
              <td>2153 sq ft</td>
            </tr>
            <tr>
              <td>5.5 BHK</td>
              <td>4456 sq ft</td>
            </tr><tr>
              <td>5.5 BHK</td>
              <td>3819  sq ft</td>
            </tr><tr>
              <td>5.5 BHK</td>
              <td>5740 sq ft</td>
            </tr>
          </tbody>
        </table>
      </div>


      {/* Download Brochure Button */}
      <button className="download-brochure-btn" onClick={downloadBrochure}>
        Download Brochure
      </button>
    </div>
  );
};

export default PropertyDetails;
