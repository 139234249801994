import React, { useState, useEffect } from 'react';
import './Property1.css';

// Import images
import img1 from './PropertyImages/9/WhatsApp Image 2024-10-13 at 5.15.19 PM.jpeg'
import img2 from './PropertyImages/9/WhatsApp Image 2024-10-13 at 5.15.22 PM (1).jpeg'
import img3 from './PropertyImages/9/WhatsApp Image 2024-10-13 at 5.15.24 PM (1).jpeg'

const images = [img1, img2, img3]; // Array of image imports

const PropertyDetails = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-slide functionality every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex + 1) % images.length
    );
  };
  const downloadBrochure = () => {
    const link = document.createElement('a');
    link.href = require('../../assets/Residential9.pdf').default; // Update this path accordingly
    link.setAttribute('download', 'Residential9.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="property-details">
      {/* Carousel Container */}
      <div className="carousel">
        <button className="carousel-button left" onClick={goToPrevious}>
          &#8249;
        </button>
        <div className="carousel-images">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index + 1}`}
              className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
            />
          ))}
        </div>
        <button className="carousel-button right" onClick={goToNext}>
          &#8250;
        </button>
      </div>

      {/* Property Title */}
      <h1 className="property-title">Kasturi - The Balmoral Riverside</h1>

      {/* Marquee Section */}
      <div className="marquee">
        <div className="marquee-content">
          Live a contemporary luxury lifestyle at The Balmoral Riverside, where modern design meets timeless elegance.
        </div>
      </div>

      {/* Property Description Section */}
      <div className="property-description">
        <h2>Property Description</h2>
        <p>
          A contemporary luxury, designed to foster modern lifestyles. The Balmoral Riverside is an address that exudes luxury with an intricate balance of style and finesse. The Balmoral is synonymous with its critically acclaimed contemporary architectural design, iconic apartments, and world-class facilities. Live a lifestyle that encompasses extravagance in its simplicity and poise. One of a kind high-rise development consisting of 4 towers with 3 & 4 bedroom apartments and breathtaking, trendy 3-level 4.5 & 5.5 bedroom penthouses.
        </p>
      </div>

      {/* Highlights Section */}
      <div className="highlights-section">
        <h2>Project Highlights</h2>
        <div className="highlights-list">
          <div className="highlight-card">
            <div className="highlight-icon">🏢</div>
            <p>Spacious and modern 3 & 4 bedroom residences.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏙️</div>
            <p>Trendy 3-level 4.5 & 5.5 bedroom penthouses.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">❄️</div>
            <p>Central VRV Air-Conditioning.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🌇</div>
            <p>Exterior viewing decks in every apartment.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏊‍♂️</div>
            <p>Extravagant leisure club with internationally designed amenities.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">♿</div>
            <p>Common areas with user-friendly features for differently abled and elderly citizens.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🌿</div>
            <p>Vast open spaces and landscaped areas covering nearly 70% of the total plot area.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🚇</div>
            <p>Minutes away from the proposed metro station at Balewadi Phata (approx. 800m).</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🌳</div>
            <p>Proposed adjoining two-acre public garden designed and developed by Kasturi.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🚗</div>
            <p>Proposed future riverside development promenade and riverside drive.</p>
          </div>
        </div>
      </div>
      {/* Property Table */}
      <div className="table-container">
        <table className="property-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Size</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3 BHK</td>
              <td>1377 sq ft, 1405 sq ft
              </td>
            </tr>
            <tr>
              <td>4 BHK</td>
              <td>1838 sq ft, 1860 sq ft</td>
            </tr>
          </tbody>
        </table>
      </div>


      {/* Download Brochure Button */}
      <button className="download-brochure-btn" onClick={downloadBrochure}>
        Download Brochure
      </button>
    </div>
  );
};

export default PropertyDetails;
