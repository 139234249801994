import React, { useState, useEffect } from 'react';
import './Property1.css';

// Import images
import img1 from './PropertyImages/2/WhatsApp Image 2024-10-13 at 3.31.03 PM (1).jpeg';
import img2 from './PropertyImages/2/WhatsApp Image 2024-10-13 at 3.31.06 PM.jpeg';
import img3 from './PropertyImages/2/WhatsApp Image 2024-10-13 at 3.31.10 PM (2).jpeg';
import brochure from '../../assets/Residential2.pdf'; // Import the PDF file

const images = [img1, img2, img3]; // Array of image imports

const PropertyDetails = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-slide functionality every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex + 1) % images.length
    );
  };

  const downloadBrochure = () => {
    const link = document.createElement('a');
    link.href = brochure; // Use the imported PDF file
    link.setAttribute('download', 'Residential2.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="property-details">
      {/* Carousel Container */}
      <div className="carousel">
        <button className="carousel-button left" onClick={goToPrevious}>
          &#8249;
        </button>
        <div className="carousel-images">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index + 1}`}
              className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
            />
          ))}
        </div>
        <button className="carousel-button right" onClick={goToNext}>
          &#8250;
        </button>
      </div>

      {/* Property Title */}
      <h1 className="property-title">Puraniks - Abitante Fiore</h1>

      {/* Marquee Section */}
      <div className="marquee">
        <div className="marquee-content">
          Experience tranquility and connectivity at Puraniks - Abitante Fiore, a unique blend of nature and modern living.
        </div>
      </div>

      {/* Property Description Section */}
      <div className="property-description">
        <h2>Property Description</h2>
        <p>
          Live in a place that's almost untouched and pure, surrounded by natural flora and fauna, while enjoying proximity to city conveniences. Located in Bavdhan, Pune, our project attracts those who value long-term living with thoughtful choices. Abitante Fiore bridges the distance between serenity and connectivity, offering a rare combination enriched with best-in-class amenities for a premium lifestyle.
        </p>
      </div>

      {/* Highlights Section */}
      <div className="highlights-section">
        <h2>Project Highlights</h2>
        <div className="highlights-list">
          <div className="highlight-card">
            <div className="highlight-icon">🌿</div>
            <p>Surrounded by natural flora and fauna</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🚗</div>
            <p>Easy access to city conveniences</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏙️</div>
            <p>Located in the serene Bavdhan, Pune</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏢</div>
            <p>Modern architecture with premium living spaces</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏊‍♂️</div>
            <p>World-class amenities for a balanced lifestyle</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🧘‍♂️</div>
            <p>Spaces designed for peace and relaxation</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏞️</div>
            <p>Beautifully landscaped gardens</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🔒</div>
            <p>24/7 security for a safe living environment</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🎯</div>
            <p>Thoughtful planning for long-term living</p>
          </div>
        </div>
      </div>

      {/* Property Table */}
      <div className="table-container">
        <table className="property-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Size</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1 BHK</td>
              <td>489 sq ft
              </td>
            </tr>
            <tr>
              <td>2 BHK</td>
              <td>616 sq ft, 692 sq ft, 733 sq ft</td>
            </tr>
            <tr>
              <td>3 BHK</td>
              <td>867 sq ft, 908 sq ft
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Download Brochure Button */}
      <button className="download-brochure-btn" onClick={downloadBrochure}>
        Download Brochure
      </button>
    </div>
  );
};

export default PropertyDetails;
