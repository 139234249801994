import React, { useState, useEffect } from 'react';
import './Commercial.css';

const Commercial = () => {
  const [filteredProperties, setFilteredProperties] = useState([
    {
      image: require('../assets/img1.jpg'),
      title: 'KBT Baner CP Deck',
      description:
        'Modern commercial space in Baner, Pune, ideal for offices and retail, with excellent connectivity and visibility.',
      link: '/property12'
    },
    {
      image: require('../assets/img2.jpg'),
      title: 'KWT Pimpri Phase 2 Deck',
      description:
        'Flexible commercial property in Pimpri, Pune, perfect for various business needs, with great connectivity to industrial hubs.',
      link: '/property13'
    },
    {
      image: require('../assets/img3.jpg'),
      title: 'Highrise Hub',
      description:
        'Prestigious high-rise in Pune offering stylish office spaces with city views and top amenities for modern enterprises.',
      link: '/property14'
    }
  ]);

  const handleFilter = (event) => {
    event.preventDefault();
    // Placeholder logic for filtering properties
    setFilteredProperties(filteredProperties); // Replace with the actual filtered results
  };
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);


  return (
    <div className="commercial-page">
      {/* Hero Section */}
      <div
        className="hero-section"
        style={{
          backgroundImage: `url(${require('../assets/buildings-1837028_1280.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '600px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
        }}
      >
        <h1>Explore Commercial Properties</h1>
      </div>

      {/* Marquee Section */}
      <div className="marquee">
        <marquee>
          Discover premium commercial spaces in Pune, ideal for offices, retail, and showrooms.
        </marquee>
      </div>

      {/* Filter Section 
      <div className="filter-section">
        <h2>Filter Commercial Properties</h2>
        <form onSubmit={handleFilter}>
          <div className="filter-inputs">
            <input type="text" placeholder="Search by location..." />
            <select>
              <option value="">Property Type</option>
              <option value="office">Office Space</option>
              <option value="retail">Retail Space</option>
              <option value="warehouse">Warehouse</option>
            </select>
            <button type="submit">Apply Filter</button>
          </div>
        </form>
      </div>*/}

      {/* Property Listing Section */}
      <div className="property-list">
        {filteredProperties.map((property, index) => (
          <div key={index} className="property-card">
            <img src={property.image} alt={property.title} />
            <h3>{property.title}</h3>
            <p>{property.description}</p>
            <a href={property.link} className="view-details">
              View Details
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Commercial;
