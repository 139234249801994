import React, { useState, useEffect } from 'react';
import './Property1.css';

// Import images
import img1 from './PropertyImages/6/WhatsApp Image 2024-10-13 at 3.34.12 PM.jpeg'
import img2 from './PropertyImages/6/WhatsApp Image 2024-10-13 at 3.34.15 PM.jpeg'
import img3 from './PropertyImages/6/WhatsApp Image 2024-10-13 at 3.34.16 PM.jpeg'
const images = [img1, img2, img3]; // Array of image imports

const PropertyDetails = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-slide functionality every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex + 1) % images.length
    );
  };
  const downloadBrochure = () => {
    const link = document.createElement('a');
    link.href = require('../../assets/Residential6.pdf').default; // Update this path accordingly
    link.setAttribute('download', 'Residential6.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="property-details">
      {/* Carousel Container */}
      <div className="carousel">
        <button className="carousel-button left" onClick={goToPrevious}>
          &#8249;
        </button>
        <div className="carousel-images">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index + 1}`}
              className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
            />
          ))}
        </div>
        <button className="carousel-button right" onClick={goToNext}>
          &#8250;
        </button>
      </div>

      {/* Property Title */}
      <h1 className="property-title">Kolte Patil - 24K Manor</h1>

      {/* Marquee Section */}
      <div className="marquee">
        <div className="marquee-content">
          Experience the pinnacle of luxury living at 24K Manor – where elegance and opulence meet modern sophistication.
        </div>
      </div>

      {/* Property Description Section */}
      <div className="property-description">
        <h2>Property Description</h2>
        <p>
          Introducing 24K Manor by Kolte Patil, an epitome of luxury living designed for the discerning elite. Nestled in a prime location, this prestigious development offers an exquisite blend of opulence and modern sophistication. With thoughtfully crafted residences, world-class amenities, and a serene environment, 24K Manor redefines fine living, catering to those who seek nothing but the best. Experience unparalleled elegance, where every detail speaks of perfection.
        </p>
      </div>

      {/* Highlights Section */}
      <div className="highlights-section">
        <h2>Project Highlights</h2>
        <div className="highlights-list">
          <div className="highlight-card">
            <div className="highlight-icon">🏢</div>
            <p>Commercial Plaza</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🚪</div>
            <p>Project Entry & Exit</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🅿️</div>
            <p>Entry for commercial parking & Parking Entry Points</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🚗</div>
            <p>Ramp access to podium & Podium access from building</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🔥</div>
            <p>Fire tender path</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🌿</div>
            <p>Secret garden with pavilion seating</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🚸</div>
            <p>Toddler & Kids play areas</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏀</div>
            <p>Adventure sports & Half basketball court</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🎾</div>
            <p>Touch tennis/pickleball & Four square</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🧘‍♂️</div>
            <p>Reflexology path & Meditation pavilion</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">💦</div>
            <p>Waterfall with lotus sculpture</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏊‍♂️</div>
            <p>Infinity edge swimming pool, Kids pool, & Aqua gym</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🌞</div>
            <p>Loungers pavilion & Sunken seating</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🍖</div>
            <p>Barbecue pavilion & Outdoor lounge</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🎤</div>
            <p>Stage & Party lawn</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🚚</div>
            <p>Service entry</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏸</div>
            <p>Squash court</p>
          </div>
        </div>
      </div>
      {/* Property Table */}
      <div className="table-container">
        <table className="property-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Size</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3 BHK</td>
              <td>1433 sq ft
              </td>
            </tr>
            <tr>
              <td>4 BHK</td>
              <td>1823 sq ft</td>
            </tr>
            <tr>
              <td>4 BHK</td>
              <td>2240 sq ft</td>
            </tr>
          </tbody>
        </table>
      </div>


      {/* Download Brochure Button */}
      <button className="download-brochure-btn" onClick={downloadBrochure}>
        Download Brochure
      </button>
    </div>
  );
};

export default PropertyDetails;
