import React, { useState, useEffect } from 'react';
import './Property12.css';

// Import images
import img1 from '../../assets/img1.jpg';
import img2 from '../../assets/img2.jpg';
import img3 from '../../assets/img3.jpg';

const images = [img1, img2, img3]; // Array of image imports

const CommercialPropertyDetails = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-slide functionality every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex + 1) % images.length
    );
  };

  const downloadBrochure = () => {
    const link = document.createElement('a');
    link.href = require('../../assets/Residential1.pdf').default; // Update this path accordingly
    link.setAttribute('download', 'Commercial1.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="property-details">
      {/* Carousel Container */}
      <div className="carousel">
        <button className="carousel-button left" onClick={goToPrevious}>
          &#8249;
        </button>
        <div className="carousel-images">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index + 1}`}
              className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
            />
          ))}
        </div>
        <button className="carousel-button right" onClick={goToNext}>
          &#8250;
        </button>
      </div>

      {/* Property Title */}
      <h1 className="property-title">KBT Baner CP Deck</h1>

      {/* Marquee Section */}
      <div className="marquee">
        <div className="marquee-content">
          Welcome to KBT Baner CP Deck – Elevate your business in Pune's premier commercial hub!
        </div>
      </div>

      {/* Property Description Section */}
      <div className="property-description">
        <h2>Property Description</h2>
        <p>
          KBT Baner CP Deck is a premier commercial property located in the heart of Baner, Pune, designed to cater to modern businesses and entrepreneurs. Offering state-of-the-art infrastructure, spacious layouts, and contemporary design, this business hub is ideal for offices, retail outlets, and showrooms. With excellent connectivity to key areas and major highways, it provides high visibility and easy accessibility for clients and employees alike. Equipped with advanced amenities, ample parking space, and a professional environment, KBT Baner CP Deck is perfect for businesses looking to establish or expand their presence in one of Pune's most sought-after commercial locations.
        </p>
      </div>

      {/* Highlights Section */}
      <div className="highlights-section">
        <h2>Project Highlights</h2>
        <div className="highlights-list">
          <div className="highlight-card">
            <div className="highlight-icon">🏢</div>
            <p>Modern office spaces with flexible layouts</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🚗</div>
            <p>Ample parking space for visitors and employees</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🌐</div>
            <p>High-speed internet and IT infrastructure</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏬</div>
            <p>Retail outlets and showrooms on the ground floor</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🛠️</div>
            <p>State-of-the-art security systems</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">💼</div>
            <p>Professional environment with 24/7 access</p>
          </div>
        </div>
      </div>

      {/* Property Table */}
      <div className="table-container">
        <h2>Office Sizes & Options</h2>
        <table className="property-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Size</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Office Space A</td>
              <td>500 sq. ft.</td>
            </tr>
            <tr>
              <td>Office Space B</td>
              <td>1000 sq. ft.</td>
            </tr>
            <tr>
              <td>Office Space C</td>
              <td>1500 sq. ft.</td>
            </tr>
            <tr>
              <td>Showroom</td>
              <td>2000 sq. ft.</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Download Brochure Button */}
      <button className="download-brochure-btn" onClick={downloadBrochure}>
        Download Brochure
      </button>
    </div>
  );
};

export default CommercialPropertyDetails;
