import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
    const [menuActive, setMenuActive] = useState(false);
    const [isLogoHovered, setIsLogoHovered] = useState(false);
    const [showNavbar, setShowNavbar] = useState(true);
    const location = useLocation();

    let lastScrollY = window.scrollY;

    const handleScroll = () => {
        if (window.scrollY > lastScrollY) {
            // Hide navbar when scrolling down
            setShowNavbar(false);
        } else {
            // Show navbar when scrolling up
            setShowNavbar(true);
        }
        lastScrollY = window.scrollY;
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const toggleMenu = () => {
        setMenuActive((prevState) => !prevState);
    };

    const closeMenu = () => {
        setMenuActive(false);
    };

    useEffect(() => {
        setIsLogoHovered(true);
        const timer = setTimeout(() => {
            setIsLogoHovered(false);
        }, 2000); // 2 seconds for the hover effect

        return () => clearTimeout(timer);
    }, [location]);

    return (
        <nav className={`navbar ${showNavbar ? 'visible' : 'hidden'}`}>
            <div className={`navbar-logo ${isLogoHovered ? 'hovered' : ''}`}>
                <img src={require('../assets/logo.png')} alt="Logo" />
            </div>
            <ul className={`navbar-links ${menuActive ? 'active' : ''}`}>
                <li>
                    <NavLink 
                        to="/" 
                        className={({ isActive }) => isActive ? 'active-link' : ''}
                        onClick={closeMenu}
                    >
                        Home
                    </NavLink>
                </li>
                <li>
                    <NavLink 
                        to="/residential" 
                        className={({ isActive }) => isActive ? 'active-link' : ''}
                        onClick={closeMenu}
                    >
                        Residential
                    </NavLink>
                </li>
                <li>
                    <NavLink 
                        to="/commercial" 
                        className={({ isActive }) => isActive ? 'active-link' : ''}
                        onClick={closeMenu}
                    >
                        Commercial
                    </NavLink>
                </li>
                <li>
                    <NavLink 
                        to="/about" 
                        className={({ isActive }) => isActive ? 'active-link' : ''}
                        onClick={closeMenu}
                    >
                        About Us
                    </NavLink>
                </li>
                <li>
                    <NavLink 
                        to="/contact" 
                        className={({ isActive }) => isActive ? 'active-link' : ''}
                        onClick={closeMenu}
                    >
                        Contact Us
                    </NavLink>
                </li>
            </ul>
            <div className="navbar-menu-icon" onClick={toggleMenu}>
            <i class="fas fa-bars"></i> 
            </div>
        </nav>
    );
};

export default Navbar;
