import React, { useState, useEffect } from 'react';
import './HeroSlider.css';

// Import images
import img2 from '../assets/skyscrapers-450793_1280.jpg';
import img1 from '../assets/city-8410353_1280.jpg';
import img3 from '../assets/architecture-5999913_1280.jpg';
import img5 from '../assets/houses-5514892_1280.jpg';

import building1 from '../assets/building1.jpg'; // New image for real estate section
import building2 from '../assets/building2.jpg'; // New image for real estate section
import building3 from '../assets/building3.jpg'; // New image for real estate section
import building4 from '../assets/building4.jpg'; // Additional image for the stack

const images = [img1, img2, img3, img5];

const HeroSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, []);

  const selectSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div>
      {/* Hero Slider Section */}
      <div className="hero-slider">
        <div className="slider-container">
          {images.map((image, index) => (
            <div
              className={`slide ${index === currentIndex ? 'active' : ''}`}
              key={index}
            >
              <img src={image} alt={`Slide ${index + 1}`} className="slide-image" />
            </div>
          ))}
        </div>

        {/* Thumbnail Carousel inside the image section */}
        <div className="slider-thumbnails">
          {images.map((image, index) => (
            <div
              className={`thumbnail ${index === currentIndex ? 'active' : ''}`}
              key={index}
              onClick={() => selectSlide(index)}
            >
              <img src={image} alt={`Thumbnail ${index + 1}`} className="thumbnail-image" />
            </div>
          ))}
        </div>
      </div>

      {/* Real Estate Section */}
      <section className="real-estate-section">
      <div className="text-section">
          <h1>The Pune Properties - Finest Real Estate Consultancy</h1>
          <p>
            At The Pune Properties, we pride ourselves on being one of Baner’s leading real
            estate consultancies. With years of experience and deep market knowledge, we are
            committed to helping you find your dream home, ideal investment, or the perfect
            commercial space. Whether you're seeking a cozy 2 BHK or an opulent 5 BHK luxury
            apartment, we specialize in connecting you with the finest properties from renowned
            builders.
          </p>
        </div>
        <div className="image-stack">
          <img src={building1} alt="Building 1" className="image image1" />
          <img src={building2} alt="Building 2" className="image image2" />
          <img src={building3} alt="Building 3" className="image image3" />
          <img src={building4} alt="Building 4" className="image image4" />
        </div>
        
      </section>
    </div>
  );
};

export default HeroSlider;
