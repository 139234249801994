import React, { useState, useEffect } from 'react';
import './PropertyDetails.css';

// Import images
import img1 from '../assets/img1.jpg';
import img2 from '../assets/img2.jpg';
import img3 from '../assets/img3.jpg';

const images = [img1, img2, img3]; // Array of image imports

const PropertyDetails = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-slide functionality every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex + 1) % images.length
    );
  };

  return (
    <div className="property-details">
      {/* Carousel Container */}
      <div className="carousel">
        <button className="carousel-button left" onClick={goToPrevious}>
          &#8249;
        </button>
        <div className="carousel-images">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index + 1}`}
              className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
            />
          ))}
        </div>
        <button className="carousel-button right" onClick={goToNext}>
          &#8250;
        </button>
      </div>

      {/* Property Title */}
      <h1 className="property-title">Livience Aleenta</h1>

      {/* Marquee Section */}
      <div className="marquee">
        <div className="marquee-content">
          Welcome to Livience Aleenta – where luxury meets serenity! Experience the best of modern living in the heart of nature.
        </div>
      </div>

      {/* Property Description Section */}
      <div className="property-description">
        <h2>Property Description</h2>
        <p>
          Nestled in the hills on one side and the Ram Nadi flowing on the other, Aleenta occupies its place of pride on the picturesque Baner-Pashan link road. This new residential hub has attracted several landmark developments, thanks to its peaceful and pollution-free environs and unstinted hill and river views. The location is very close to the city and the Hinjewadi Software Park & Mumbai Expressway. Commuting is easy and the social infrastructure is in place for the residents to enjoy a fulfilled life.
        </p>
      </div>

      {/* Highlights Section */}
<div className="highlights-section">
    <h2>Project Highlights</h2>
    <div className="highlights-list">
        <div className="highlight-card">
            <div className="highlight-icon">🏢</div> {/* You can replace this with an actual icon */}
            <p>5 towers of over 30 storeys</p>
        </div>
        <div className="highlight-card">
            <div className="highlight-icon">🛌</div>
            <p>Offering 3, 4, 4.5, 5 & 6 BHK homes</p>
        </div>
        <div className="highlight-card">
            <div className="highlight-icon">🏊‍♂️</div>
            <p>Over 1 lac sq. ft. of recreational lifestyle podium</p>
        </div>
        <div className="highlight-card">
            <div className="highlight-icon">🏠</div>
            <p>Multi-level club around 25,000 sq. ft. with host of lifestyle amenities</p>
        </div>
        <div className="highlight-card">
            <div className="highlight-icon">🧘‍♀️</div>
            <p>Vaastu compliant homes with sweeping decks</p>
        </div>
        <div className="highlight-card">
            <div className="highlight-icon">🌄</div>
            <p>Panoramic views of hills and greens</p>
        </div>
        <div className="highlight-card">
            <div className="highlight-icon">💨</div>
            <p>Cross ventilated homes with abundant natural light</p>
        </div>
        <div className="highlight-card">
            <div className="highlight-icon">🏙️</div>
            <p>Duplex & penthouses on higher floor</p>
        </div>
        <div className="highlight-card">
            <div className="highlight-icon">🚗</div>
            <p>Ample parking space for all apartments</p>
        </div>
    </div>
</div>


      {/* Property Table */}
      <div className="table-container">
        <table className="property-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Size</th>
              <th>Amenities</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3 BHK</td>
              <td>1500 sq. ft.</td>
              <td>Swimming Pool, Gym, Garden</td>
            </tr>
            <tr>
              <td>4 BHK</td>
              <td>2000 sq. ft.</td>
              <td>Swimming Pool, Gym, Garden, Clubhouse</td>
            </tr>
            <tr>
              <td>5 BHK</td>
              <td>2500 sq. ft.</td>
              <td>Swimming Pool, Gym, Garden, Clubhouse, Security</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Download Brochure Button */}
      <button className="download-brochure-btn">
        <a href="/path-to-brochure.pdf" target="_blank" rel="noopener noreferrer">Download Brochure</a>
      </button>
    </div>
  );
};

export default PropertyDetails;
