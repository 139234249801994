import React, { useEffect } from 'react';
import './TermsAndConditions.css'; // Import your CSS file

const TermsAndConditions = () => {
    // Scroll to the top when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="terms-and-conditions-container">
            <h1>Terms & Conditions</h1>
            <p>Welcome to The Pune Properties. By accessing and using our website and services, you agree to the following terms and conditions. Please read them carefully before proceeding.</p>
            
            <h2>1. Introduction</h2>
            <p>These Terms and Conditions ("Terms") govern your use of The Pune Properties website and services. By engaging with us, you agree to comply with these Terms. If you do not agree with any part of these Terms, please refrain from using our services.</p>

            <h2>2. Services Offered</h2>
            <p>The Pune Properties provides real estate consultancy services, including but not limited to:</p>
            <ul>
                <li>Property consultations</li>
                <li>Site visits</li>
                <li>Property listings and marketing</li>
                <li>Investment advisory</li>
                <li>Property management</li>
            </ul>
            <p>We act as intermediaries between buyers, sellers, landlords, and tenants. Our services may be subject to additional terms, which will be provided at the time of engagement.</p>

            <h2>3. Use of the Website</h2>
            <p>You may use the website for lawful purposes only.</p>
            <p>You agree not to copy, distribute, or modify any part of the website without prior written permission.</p>
            <p>Misuse of the website, such as hacking or uploading harmful content, will result in termination of access and legal action if necessary.</p>

            <h2>4. Booking and Payments</h2>
            <p>All consultations, property visits, and other services must be booked in advance.</p>
            <p>Payments for services rendered by The Pune Properties must be made as agreed upon during the engagement process.</p>
            <p>Non-payment or delayed payment may result in the cancellation of services or additional charges.</p>

            <h2>5. Property Information</h2>
            <p>The property listings and details provided on our website or through consultation are based on information from property owners, builders, or third-party sources.</p>
            <p>While we make every effort to ensure accuracy, The Pune Properties does not guarantee the completeness or reliability of the information.</p>
            <p>It is the responsibility of the buyer or investor to conduct due diligence before making any property-related decisions.</p>

            <h2>6. Client Obligations</h2>
            <p>By using our services, you agree to:</p>
            <ul>
                <li>Provide accurate and complete information to facilitate the process.</li>
                <li>Respect the appointment times for consultations and site visits.</li>
                <li>Inform The Pune Properties of any changes in your requirements or personal information promptly.</li>
            </ul>

            <h2>7. Third-Party Relationships</h2>
            <p>The Pune Properties may provide recommendations or introductions to third-party services such as legal, financial, or home inspection services. However, we do not endorse or assume liability for the actions, advice, or services provided by these third parties. Clients are encouraged to engage with third parties independently and at their discretion.</p>

            <h2>8. Limitation of Liability</h2>
            <p>The Pune Properties will not be liable for any loss or damage incurred as a result of using our services, including but not limited to financial losses, legal disputes, or delays in transactions.</p>
            <p>While we strive to provide timely and accurate information, we do not guarantee that our website or services will be free from errors or interruptions.</p>

            <h2>9. Cancellation Policy</h2>
            <p>Clients who wish to cancel any of our services are subject to our Cancellation Policy, which is outlined separately on our website. Please review this policy before making any cancellations.</p>

            <h2>10. Privacy Policy</h2>
            <p>We respect your privacy and are committed to protecting your personal information. Please refer to our Privacy Policy to understand how we collect, use, and protect your data.</p>

            <h2>11. Intellectual Property</h2>
            <p>All content on this website, including text, images, logos, and graphics, is the property of The Pune Properties and is protected by copyright laws.</p>
            <p>You may not reproduce, distribute, or create derivative works from our content without express written consent.</p>

            <h2>12. Dispute Resolution</h2>
            <p>Any disputes arising from the use of our services or these Terms shall be resolved amicably. If unresolved, disputes shall be subject to the exclusive jurisdiction of the courts in Pune, Maharashtra.</p>

            <h2>13. Modifications to Terms</h2>
            <p>The Pune Properties reserves the right to update or modify these Terms at any time. We will notify users of any significant changes. Continued use of our services following the changes signifies acceptance of the modified Terms.</p>

            <h2>14. Contact Information</h2>
            <p>For any questions or concerns regarding these Terms and Conditions, please contact us at:</p>
            <p>The Pune Properties<br />Baner, Pune</p>
        </div>
    );
};

export default TermsAndConditions;
