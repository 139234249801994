import React, { useState, useEffect } from 'react';
import './Partnership.css'; // Ensure this CSS file exists and is correctly linked

// Import images for partnership logos
import godrejLogo from '../assets/Partnershiplogo/Godrej.png';
import kalpataruLogo from '../assets/Partnershiplogo/kalpataru.png';
import kolteLogo from '../assets/Partnershiplogo/kolpe.png';
import vtpLogo from '../assets/Partnershiplogo/vtp.png';
import kasturiLogo from '../assets/Partnershiplogo/kasturi.png';
import mahindraLogo from '../assets/Partnershiplogo/mahindra.png';
import sobhaLogo from '../assets/Partnershiplogo/sobha.jpeg';
import kumarLogo from '../assets/Partnershiplogo/kumar.png';
import nyatiLogo from '../assets/Partnershiplogo/nyati.png';

// Define the partnership logos array
const partnershipLogos = [
    { name: "Godrej Properties", imageUrl: godrejLogo },
    { name: "Kalpataru Ltd.", imageUrl: kalpataruLogo },
    { name: "Kolte Patil Developers Ltd.", imageUrl: kolteLogo },
    { name: "VTP Realty", imageUrl: vtpLogo },
    { name: "Kasturi Housing", imageUrl: kasturiLogo },
    { name: "Mahindra Lifespaces Developers", imageUrl: mahindraLogo },
    { name: "SOBHA Limited", imageUrl: sobhaLogo },
    { name: "Kumar Properties", imageUrl: kumarLogo },
    { name: "Nyati", imageUrl: nyatiLogo },
];

const Partnership = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsPerPage = 4; // Number of logos to display at a time
    const totalLogos = partnershipLogos.length;

    // Calculate the number of visible logos based on the current index
    const visibleLogos = partnershipLogos.slice(currentIndex, currentIndex + itemsPerPage);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Handle pagination dot click, move one logo at a time
    const handleDotClick = (index) => {
        const newIndex = index; // Set to clicked dot index
        // Ensure the new index does not exceed the last valid index
        if (newIndex <= totalLogos - itemsPerPage) {
            setCurrentIndex(newIndex);
        }
    };

    // Handle next and previous functionality
    const handleNext = () => {
        if (currentIndex + 1 <= totalLogos - itemsPerPage) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
    };

    // Create pagination dots
    const dots = Array.from({ length: totalLogos - itemsPerPage + 1 }, (_, index) => (
        <div 
            key={index} 
            className={`dot ${currentIndex === index ? 'active' : ''}`} 
            onClick={() => handleDotClick(index)} // Pass the index to the click handler
        ></div>
    ));

    return (
        <section className="partnerships">
            <div className="content">
                <h2> Our Partnerships</h2>
                <p>
                    Over the years, our strong presence in the market has earned us partnerships with some of the biggest names in the real estate market. Our all-inclusive services involve:
                </p>
                <div className="partnership-cards">
                    {visibleLogos.map((logo, index) => (
                        <div className="partnership-card" key={index}>
                            <img src={logo.imageUrl} alt={logo.name} />
                        </div>
                    ))}
                </div>
                
                <div className="pagination-dots">
                    {dots}
                </div>
            </div>
        </section>
    );
};

export default Partnership;
