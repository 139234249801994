import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './WhatsappChat.css';

const WhatsappChat = () => {
  const whatsappNumber = '8446231017'; // Replace with your WhatsApp number
  const handleClick = () => {
    const whatsappUrl = `https://wa.me/${whatsappNumber}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="whatsapp-chat" onClick={handleClick}>
      <div className="whatsapp-background">
        <FaWhatsapp className="whatsapp-icon" />
      </div>
    </div>
  );
};

export default WhatsappChat;
