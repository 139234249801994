import React, { useState, useEffect } from 'react';
import './Property1.css';

// Import images
import img1 from './PropertyImages/5/WhatsApp Image 2024-10-13 at 3.07.06 PM.jpeg'
import img2 from './PropertyImages/5/WhatsApp Image 2024-10-13 at 3.07.07 PM.jpeg'
import img3 from './PropertyImages/5/WhatsApp Image 2024-10-13 at 3.07.15 PM (2).jpeg'

const images = [img1, img2, img3]; // Array of image imports

const PropertyDetails = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-slide functionality every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex + 1) % images.length
    );
  };
  const downloadBrochure = () => {
    const link = document.createElement('a');
    link.href = require('../../assets/Residential5.pdf').default; // Update this path accordingly
    link.setAttribute('download', 'Residential5.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="property-details">
      {/* Carousel Container */}
      <div className="carousel">
        <button className="carousel-button left" onClick={goToPrevious}>
          &#8249;
        </button>
        <div className="carousel-images">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index + 1}`}
              className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
            />
          ))}
        </div>
        <button className="carousel-button right" onClick={goToNext}>
          &#8250;
        </button>
      </div>

      {/* Property Title */}
      <h1 className="property-title">Kolte Patil - 24K Altura</h1>

      {/* Marquee Section */}
      <div className="marquee">
        <div className="marquee-content">
          Discover unparalleled luxury at 24K Altura – a sanctuary where urban elegance meets nature's tranquility.
        </div>
      </div>

      {/* Property Description Section */}
      <div className="property-description">
        <h2>Property Description</h2>
        <p>
          At 24K Altura, every aspect of the layout design has been meticulously crafted to perfection by a highly respected veteran architect, known as the creator of the modern urban skyline. From the lobby, the palatial living room, and the spacious bedrooms, to the enticing terraces and the kitchen, each detail reflects the architect's classic touch. The goal is to provide residents with more life, more nature, and more opportunities for celebration.
        </p>
      </div>

      {/* Highlights Section */}
      <div className="highlights-section">
        <h2>Project Highlights</h2>
        <div className="highlights-list">
          <div className="highlight-card">
            <div className="highlight-icon">🚗</div>
            <p>A well-designed entrance and exit ensure smooth accessibility for residents, offering both security and convenience.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🌳</div>
            <p>A scenic, dedicated pathway for your morning jog or evening stroll, surrounded by lush greenery.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🎭</div>
            <p>A stunning open-air amphitheater designed for community gatherings, cultural events, and entertainment.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🎉</div>
            <p>A spacious, beautifully landscaped party lawn, perfect for hosting celebrations and events under the stars.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏀</div>
            <p>Engage in a range of exciting outdoor games in a dedicated space designed for recreation and fun.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🍔</div>
            <p>Celebrate with family and friends in this elegant outdoor area, complete with a BBQ counter and alfresco dining options.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">👶</div>
            <p>A safe and vibrant play area designed to keep kids active and entertained.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🚿</div>
            <p>Convenient changing rooms located near the pool and recreation areas, offering added comfort.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🤸‍♂️</div>
            <p>A specially designed zone for toddlers to play and explore in a secure and joyful environment.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏖️</div>
            <p>A fun-filled sand pit where children can engage in creative play and enjoy outdoor activities.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🧘‍♀️</div>
            <p>A serene space dedicated to practicing yoga and meditation amidst nature, promoting wellness and tranquility.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🦶</div>
            <p>A carefully crafted reflexology path for a therapeutic walking experience, offering relaxation and health benefits.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">💆‍♂️</div>
            <p>A secluded spa pool surrounded by lush greenery, offering a tranquil, rejuvenating experience.</p>
          </div>
        </div>
      </div>
      {/* Property Table */}
      <div className="table-container">
        <table className="property-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Size</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3 BHK</td>
              <td>1136 sq ft, 1361 sq ft
              </td>
            </tr>
            <tr>
              <td>4 BHK</td>
              <td>1695 sq ft, 1737 sq ft</td>
            </tr>
          </tbody>
        </table>
      </div>


      {/* Download Brochure Button */}
      <button className="download-brochure-btn" onClick={downloadBrochure}>
        Download Brochure
      </button>
    </div>
  );
};

export default PropertyDetails;
