import React, { useState, useEffect } from 'react';
import './Property1.css';

// Import images
import img1 from './PropertyImages/1/WhatsApp Image 2024-10-13 at 3.07.06 PM.jpeg';
import img2 from './PropertyImages/1/WhatsApp Image 2024-10-13 at 3.07.15 PM (2).jpeg';
import img3 from './PropertyImages/1/WhatsApp Image 2024-10-13 at 3.11.52 PM.jpeg';
import brochure from '../../assets/Residential1.pdf'; // Import the PDF file

const images = [img1, img2, img3]; // Array of image imports

const PropertyDetails = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-slide functionality every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex + 1) % images.length
    );
  };

  const downloadBrochure = () => {
    const link = document.createElement('a');
    link.href = brochure; // Use the imported PDF file
    link.setAttribute('download', 'Residential1.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="property-details">
      {/* Carousel Container */}
      <div className="carousel">
        <button className="carousel-button left" onClick={goToPrevious}>
          &#8249;
        </button>
        <div className="carousel-images">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index + 1}`}
              className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
            />
          ))}
        </div>
        <button className="carousel-button right" onClick={goToNext}>
          &#8250;
        </button>
      </div>

      {/* Property Title */}
      <h1 className="property-title">Livience Aleenta</h1>

      {/* Marquee Section */}
      <div className="marquee">
        <div className="marquee-content">
          Welcome to Livience Aleenta – where luxury meets serenity! Experience the best of modern living in the heart of nature.
        </div>
      </div>

      {/* Property Description Section */}
      <div className="property-description">
        <h2>Property Description</h2>
        <p>
          Livience Aleenta is nestled in the hills on one side and the Ram Nadi flowing on the other, occupying its place of pride on the picturesque Baner-Pashan link road. This emerging residential hub is known for its peaceful and pollution-free surroundings, with uninterrupted hill and river views. Its proximity to the city, the Hinjewadi Software Park, and the Mumbai Expressway makes commuting a breeze, while the well-developed social infrastructure ensures a comfortable and fulfilling life for its residents.
        </p>
      </div>

      {/* Highlights Section */}
      <div className="highlights-section">
        <h2>Project Highlights</h2>
        <div className="highlights-list">
          <div className="highlight-card">
            <div className="highlight-icon">🏢</div>
            <p>5 towers of over 30 storeys</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🛌</div>
            <p>Offering 3, 4, 4.5, 5 & 6 BHK homes</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏊‍♂️</div>
            <p>Over 1 lac sq. ft. of recreational lifestyle podium</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏠</div>
            <p>Multi-level club around 25,000 sq. ft. with a host of lifestyle amenities</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🧘‍♀️</div>
            <p>Vaastu compliant homes with sweeping decks</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🌄</div>
            <p>Panoramic views of hills and greens</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">💨</div>
            <p>Cross-ventilated homes with abundant natural light</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏙️</div>
            <p>Duplex & penthouses on higher floors</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🚗</div>
            <p>Ample parking space for all apartments</p>
          </div>
        </div>
      </div>

      {/* Property Table */}
      <div className="table-container">
        <table className="property-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Size</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3 BHK</td>
              <td>1438 sq ft
              </td>
            </tr>
            <tr>
              <td>4 BHK</td>
              <td>1900 sq ft</td>
            </tr>
            <tr>
              <td>4.5 BHK</td>
              <td>2200 sq ft</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Download Brochure Button */}
      <button className="download-brochure-btn" onClick={downloadBrochure}>
        Download Brochure
      </button>
    </div>
  );
};

export default PropertyDetails;
