import React from 'react';
import './Property12.css'; // Adjust the path if needed

// Importing images directly
import img1 from '../../assets/img1.jpg';
import img2 from '../../assets/img2.jpg';
import img3 from '../../assets/img3.jpg';

const Property13 = () => {
    const property = {
        title: "KWT Pimpri Phase 2 Deck",
        description: "KWT Pimpri Phase 2 Deck is a prime commercial property strategically located in Pimpri, Pune, offering an exceptional space for businesses to thrive. Designed with modern architecture and flexible floor plans, it caters to a wide range of commercial needs, from corporate offices to retail and showroom spaces. The property ensures seamless connectivity to key industrial and business hubs, making it highly convenient for both employees and clients. Featuring world-class amenities, ample parking, and a vibrant business environment, KWT Pimpri Phase 2 Deck is an ideal choice for companies seeking a professional space in one of Pune’s rapidly growing commercial zones.",
        images: [img1, img2, img3],
        highlights: ["Prime Location", "Modern Architecture", "Flexible Floor Plans", "World-Class Amenities", "Ample Parking"]
    };

    return (
        <div className="commercial-property-details">
            <h1 className="commercial-property-title">{property.title}</h1>
            <div className="commercial-carousel">
                <div className="commercial-carousel-images">
                    {property.images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`${property.title} - image ${index + 1}`}
                            className={`commercial-carousel-image ${index === 0 ? 'active' : ''}`}
                        />
                    ))}
                </div>
            </div>
            <div className="commercial-property-description">
                <h2>About the Property</h2>
                <p>{property.description}</p>
            </div>
            <div className="commercial-highlights-section">
                <h2>Property Highlights</h2>
                <div className="commercial-highlights-list">
                    {property.highlights.map((highlight, index) => (
                        <div key={index} className="commercial-highlight-card">
                            <p>{highlight}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="commercial-table-container">
                <h2>Specifications</h2>
                <table className="commercial-property-table">
                    <thead>
                        <tr>
                            <th>Specification</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Location</td>
                            <td>Pimpri, Pune</td>
                        </tr>
                        <tr>
                            <td>Area</td>
                            <td>5000 sq ft</td>
                        </tr>
                        <tr>
                            <td>Parking</td>
                            <td>Ample</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button className="commercial-download-brochure-btn">
                <a href={`/brochures/kwt_pimpri_phase_2_deck.pdf`} download>
                    Download Brochure
                </a>
            </button>
        </div>
    );
};

export default Property13;
