import React, { useEffect } from 'react';
import { HashRouter as Router, Route, Routes, useLocation } from 'react-router-dom'; // Changed to HashRouter
import 'font-awesome/css/font-awesome.min.css';
import Navbar from './components/Navbar';
import EnquireButton from './components/EnquireButton';
import HeroSlider from './components/HeroSlider';
import FifthSection from './components/FifthSection';
import TestimonialCarousel from './components/TestimonialCarousel';
import Footer from './components/Footer';
import BlogSection from './components/BlogSection';
import Residential from './components/Residential';
import Commercial from './components/Commercial';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import PropertyDetails from './components/PropertyDetails';
import ScrollToTopButton from './components/ScrollToTopButton';
import WhatsappChat from './components/WhatsappChat';
import MetaPixel from './components/MetaPixel';
import Property1 from './components/ResidentialProperties/Property1';
import Property2 from './components/ResidentialProperties/Property2';
import Property3 from './components/ResidentialProperties/Property3';
import Property4 from './components/ResidentialProperties/Property4';
import Property5 from './components/ResidentialProperties/Property5';
import Property6 from './components/ResidentialProperties/Property6';
import Property7 from './components/ResidentialProperties/Property7';
import Property8 from './components/ResidentialProperties/Property8';
import Property9 from './components/ResidentialProperties/Property9';
import Property10 from './components/ResidentialProperties/Property10';
import Property11 from './components/ResidentialProperties/Property11';
import Property12 from './components/CommercialProperties/Property12';
import Property13 from './components/CommercialProperties/Property13';
import Property14 from './components/CommercialProperties/Property14';
import Partnership from './components/Partnership';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import Disclaimer from './components/Disclaimer';
import testimonials from './components/TestimonialCarousel';
import ReactGA from 'react-ga';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const propertyData = {
    title: "Livience Aleenta",
    description: `Nestled in the hills on one side and the Ram Nadi flowing on the other, Aleenta occupies its place of pride on the picturesque Baner-Pashan link road...`,
    highlights: [
      "5 towers of over 30 storeys",
      "Offering 3, 4, 4.5, 5 & 6 BHK homes",
      "Over 1 lac sq. ft. of recreational lifestyle podium",
      "Multi-level club around 25,000 sq. ft. with host of lifestyle amenities",
      "Vaastu compliant homes with sweeping decks",
      "Panoramic views of hills and greens",
      "Cross ventilated homes with abundant natural light",
      "Duplex & penthouses on higher floor",
      "Ample parking space for all apartments",
    ],
    brochureLink: "/path/to/livience-aleenta-brochure.pdf",
  };

  return (
    <>
      <MetaPixel />
      <Navbar />
      <EnquireButton />
      <WhatsappChat />
      <ScrollToTopButton />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeroSlider />
              <FifthSection />
              <TestimonialCarousel />
              <BlogSection />
              <Partnership />
            </>
          }
        />
        <Route path="/residential" element={<Residential />} />
        <Route path="/commercial" element={<Commercial />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/partnership" element={<Partnership />} />
        <Route path="/testimonials" element={<TestimonialCarousel />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/property1" element={<Property1 />} />
        <Route path="/property2" element={<Property2 />} />
        <Route path="/property3" element={<Property3 />} />
        <Route path="/property4" element={<Property4 />} />
        <Route path="/property5" element={<Property5 />} />
        <Route path="/property6" element={<Property6 />} />
        <Route path="/property7" element={<Property7 />} />
        <Route path="/property8" element={<Property8 />} />
        <Route path="/property9" element={<Property9 />} />
        <Route path="/property10" element={<Property10 />} />
        <Route path="/property11" element={<Property11 />} />
        <Route path="/property12" element={<Property12 />} />
        <Route path="/property13" element={<Property13 />} />
        <Route path="/property14" element={<Property14 />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/terms&conditions" element={<TermsAndConditions />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        {/* Property Details page */}
        <Route
          path="/property-details"
          element={
            <PropertyDetails
              title={propertyData.title}
              description={propertyData.description}
              highlights={propertyData.highlights}
              brochureLink={propertyData.brochureLink}
            />
          }
        />
      </Routes>
      <Footer />
    </>
  );
};

const MainApp = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default MainApp;
