import React, { useState, useEffect } from 'react';
import './Residential.css';
import { Link } from 'react-router-dom'; // Import Link for navigation

const Residential = () => {
  const [filteredProperties, setFilteredProperties] = useState([
    {
      id: 1,
      title: "Aleenta",
      description: "Surrounded by hills and the Ram Nadi, Aleenta on the Baner-Pashan link road offers seamless commuting and a fulfilling lifestyle",
      img: require('./ResidentialProperties/PropertyImages/1/WhatsApp Image 2024-10-13 at 3.07.06 PM.jpeg'), 
    },
    {
      id: 2,
      title: "Abitante Fiore",
      description: "Experience untouched nature while enjoying city conveniences. Abitante Fiore bridges the gap between serenity and connectivity.",
      img: require('./ResidentialProperties/PropertyImages/2/WhatsApp Image 2024-10-13 at 3.31.03 PM (1).jpeg'), 
    },
    {
      id: 3,
      title: "Maximus",
      description: "A lifestyle masterpiece redefining luxury living with spacious layouts and awe-inspiring views in the heart of Punawale.",
      img: require('./ResidentialProperties/PropertyImages/3/WhatsApp Image 2024-10-13 at 3.20.47 PM (1).jpeg'), 
    },
    {
      id: 4,
      title: "M-soulstrings",
      description: "M–Soul Strings offers luxuriously designed apartments with state-of-the-art amenities, enhancing your quality of life.",
      img: require('./ResidentialProperties/PropertyImages/4/WhatsApp Image 2024-10-13 at 3.23.35 PM (1).jpeg'), 
    },
    {
      id: 5,
      title: "24K Altura",
      description: "Meticulously crafted residences offering more quality of life, nature, and opportunities for celebration.",
      img: require('./ResidentialProperties/PropertyImages/5/WhatsApp Image 2024-10-13 at 3.07.06 PM.jpeg'), 
    },
    {
      id: 6,
      title: "24K Manor",
      description: "An epitome of luxury living designed for the elite, blending opulence with modern sophistication.",
      img: require('./ResidentialProperties/PropertyImages/6/WhatsApp Image 2024-10-13 at 3.34.12 PM.jpeg'), 
    },
    {
      id: 7,
      title: "The Balmoral Towers",
      description: "A hyper-efficient residential icon blending aesthetics with functionality, designed for vibrant community living.",
      img: require('./ResidentialProperties/PropertyImages/7/WhatsApp Image 2024-10-13 at 2.11.33 PM.jpeg'), 
    },
    {
      id: 8,
      title: "The Balmoral Hillside",
      description: "Wake up to scenic hill views in a life meticulously woven around the hillside, offering a blend of luxury and nature.",
      img: require('./ResidentialProperties/PropertyImages/8/WhatsApp Image 2024-10-13 at 3.01.13 PM.jpeg'), 
    },
    {
      id: 9,
      title: "The Balmoral Riverside",
      description: "Designed for modern lifestyles, this high-rise development offers breathtaking apartments with world-class facilities.",
      img: require('./ResidentialProperties/PropertyImages/9/WhatsApp Image 2024-10-13 at 5.15.19 PM.jpeg'), 
    },
    {
      id: 10,
      title: "The Ornate",
      description: "Reflecting richness and style in a prime locality of Baner, this twin tower project offers luxury apartments.",
      img: require('./ResidentialProperties/PropertyImages/10/WhatsApp Image 2024-10-13 at 3.27.55 PM.jpeg'), 
    },
    {
      id: 11,
      title: "Rohan Ekam",
      description: "A luxury residential project in Balewadi featuring spacious apartments designed with perfect ventilation and natural light.",
      img: require('./ResidentialProperties/PropertyImages/11/WhatsApp Image 2024-10-13 at 3.37.15 PM (1).jpeg'), 
    },
  ]);

  const handleFilter = (event) => {
    event.preventDefault();
    // Apply filter logic here
    setFilteredProperties(filteredProperties);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <div className="residential-page">
      {/* Hero Section */}
      <div
        className="hero-section"
        style={{
          backgroundImage: `url(${require('../assets/home-3625018_1280.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '600px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
        }}
      >
        <h1>Explore Residential Properties</h1>
      </div>

      {/* Breadcrumbs */}
      

      {/* Marquee Section */}
      <div className="marquee">
        <marquee>
        Discover the finest homes with The Pune Properties, where your dream lifestyle begins. 

        </marquee>
      </div>

      {/* Filter Section 
      <div className="filter-section">
        <h2>Filter Residential Properties</h2>
        <form onSubmit={handleFilter}>
          <div className="filter-inputs">
            <input type="text" placeholder="Search by location..." />
            <select>
              <option value="">Property Type</option>
              <option value="apartment">Apartment</option>
              <option value="villa">Villa</option>
              <option value="duplex">Duplex</option>
            </select>
            <button type="submit">Apply Filter</button>
          </div>
        </form>
      </div>*/}

      {/* Property Listing Section */}
      <div className="property-list">
        {filteredProperties.map(property => (
          <div className="property-card" key={property.id}>
            <img src={property.img} alt={property.title} />
            <h3>{property.title}</h3>
            <p>{property.description}</p>
            <Link to={`/property${property.id}`} className="view-details">View Details</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Residential;
