import React, { useState, useEffect } from 'react';
import './Property1.css';

// Import images
import img1 from './PropertyImages/4/WhatsApp Image 2024-10-13 at 3.23.35 PM (1).jpeg';
import img2 from './PropertyImages/4/WhatsApp Image 2024-10-13 at 3.23.40 PM.jpeg';
import img3 from './PropertyImages/4/WhatsApp Image 2024-10-13 at 3.27.56 PM.jpeg';

const images = [img1, img2, img3]; // Array of image imports

const PropertyDetails = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-slide functionality every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const downloadBrochure = () => {
    const link = document.createElement('a');
    link.href = require('../../assets/Residential5.pdf').default; // Update this path accordingly
    link.setAttribute('download', 'Residential5.pdf'); // Make sure the filename is appropriate
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="property-details">
      {/* Carousel Container */}
      <div className="carousel">
        <button 
          className="carousel-button left" 
          onClick={goToPrevious}
          aria-label="Previous Slide"
        >
          &#8249;
        </button>
        <div className="carousel-images">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index + 1}`}
              className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
            />
          ))}
        </div>
        <button 
          className="carousel-button right" 
          onClick={goToNext}
          aria-label="Next Slide"
        >
          &#8250;
        </button>
      </div>

      {/* Property Title */}
      <h1 className="property-title">Malpani: M-soulstrings</h1>

      {/* Marquee Section */}
      <div className="marquee">
        <div className="marquee-content">
          M–Soul Strings by Malpani Estates – Experience luxury living in a space designed to elevate your lifestyle.
        </div>
      </div>

      {/* Property Description Section */}
      <div className="property-description">
        <h2>Property Description</h2>
        <p>
          M–Soul Strings by Malpani Estates is destined to be the new realty capital of the city. The project offers stupendously designed apartments with state-of-the-art amenities and facilities. Embrace luxury living in these stylishly crafted homes, where every detail is designed to enhance your quality of life. With ready-to-view sample flats, you can experience your future home today!
        </p>
      </div>

      {/* Highlights Section */}
      <div className="highlights-section">
        <h2>Project Overview</h2>
        <div className="highlights-list">
          <div className="highlight-card">
            <div className="highlight-icon">🌍</div>
            <p>Land Parcel: 5.20 Acres</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏢</div>
            <p>Total Towers: 4</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏗️</div>
            <p>Tower Design: B2 + B1 + LG + Podium + 27 Floors</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🛋️</div>
            <p>3 BHK Premium Residences: 1302 Sq. ft.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🏠</div>
            <p>4 BHK Premium Residences: 2060 Sq. ft.</p>
          </div>
          <div className="highlight-card">
            <div className="highlight-icon">🔑</div>
            <p>Sample Flat Ready for Viewing</p>
          </div>
        </div>
      </div>

      {/* Property Table */}
      <div className="table-container">
        <table className="property-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Size</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3 BHK</td>
              <td>1302 sq ft
              </td>
            </tr>
            <tr>
              <td>4 BHK</td>
              <td>2016 sq. ft.</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Download Brochure Button */}
      <button className="download-brochure-btn" onClick={downloadBrochure}>
        Download Brochure
      </button>
    </div>
  );
};

export default PropertyDetails;
