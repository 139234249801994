import React, { useState } from 'react';
import './BlogSection.css';
import founder1 from '../assets/founder1.jpg'; // Import an appropriate image
import founder2 from '../assets/founder2.jpg'; // Import an appropriate image

const partnershipLogos = [
    { name: "Godrej Properties", imageUrl: require('../assets/Partnershiplogo/Godrej.png') },
    { name: "Kalpataru Ltd.", imageUrl: require('../assets/Partnershiplogo/kalpataru.png') },
    { name: "Kolte Patil Developers Ltd.", imageUrl: require('../assets/Partnershiplogo/kolpe.png') },
    { name: "VTP Realty", imageUrl: require('../assets/Partnershiplogo/vtp.png') },
    { name: "Kasturi Housing", imageUrl: require('../assets/Partnershiplogo/kasturi.png') },
    { name: "Mahindra Lifespaces Developers", imageUrl: require('../assets/Partnershiplogo/mahindra.png') },
    { name: "SOBHA Limited", imageUrl: require('../assets/Partnershiplogo/sobha.jpeg') },
    { name: "Kumar Properties", imageUrl: require('../assets/Partnershiplogo/kumar.png') },
    { name: "Nyati", imageUrl: require('../assets/Partnershiplogo/nyati.png') },
];

const blogPosts = [
    {
        imageUrl: require("../assets/cer3.jpg"),
        link: "/news/1"
    },
    {
        imageUrl: require("../assets/cer2.jpg"),
        link: "/news/2"
    },
    {
        imageUrl: require("../assets/cer1.jpg"),
        link: "/news/3"
    },
    {
        imageUrl: require("../assets/cer4.jpg"),
        link: "/news/3"
    },{
        imageUrl: require("../assets/cer5.jpg"),
        link: "/news/3"
    },{
        imageUrl: require("../assets/cer6.jpg"),
        link: "/news/3"
    },
];

const BlogSection = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsPerPage = 4; // Change this number to display more or fewer items per page
    const totalPages = Math.ceil(partnershipLogos.length / itemsPerPage);

    const handleDotClick = (index) => {
        setCurrentIndex(index);
    };

    const visibleLogos = partnershipLogos.slice(
        currentIndex * itemsPerPage,
        currentIndex * itemsPerPage + itemsPerPage
    );

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % partnershipLogos.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + partnershipLogos.length) % partnershipLogos.length);
    };

    return (
        <>
            <section className="blog-section">
                <div className="blog-container">
                    <h2>Our Achievements</h2>
                    <div className="blog-cards">
                        {blogPosts.map((post, index) => (
                            <div className="blog-card" key={index}>
                                <img src={post.imageUrl} alt={post.title} />
                                
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <div className="why-choose-us">
                <div className="content">
                    <h2>Why Choose Us?</h2>
                    <div className="why-cards">
                        <div className="why-card">
                            <div className="icon">🏘️</div>
                            <h3>Diverse<br/> Portfolio</h3>
                            <p>We offer a wide array of residential options, from contemporary 2BHK apartments to lavish 5 BHK residences, each designed with elegance and comfort in mind.</p>
                        </div>
                        <div className="why-card">
                            <div className="icon">📍</div>
                            <h3>Local<br/> Expertise</h3>
                            <p>Our team has extensive knowledge of Pune's real estate landscape, ensuring you find the perfect home tailored to your needs.</p>
                        </div>
                        <div className="why-card">
                            <div className="icon">🛠️</div>
                            <h3>Comprehensive Services</h3>
                            <p>From property search and site visits to paperwork and negotiation, we handle every aspect of your real estate journey with professionalism and care.</p>
                        </div>
                        <div className="why-card">
                            <div className="icon">🏗️</div>
                            <h3>Trusted<br/> Builders</h3>
                            <p>Partnering with established developers such as Lodha and Kolte Patil, we provide access to top-tier projects that embody quality and innovation.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Partnership Section 
            <section className="partnerships">
                <div className="content">
                    <h2>Our Partnerships</h2>
                    <p>
                        Over the years, our strong presence in the market has earned us partnerships with some of the biggest names in the real estate market. Our all-inclusive services involve:
                    </p>
                    <div className="partnership-cards">
                        {visibleLogos.map((logo, index) => (
                            <div className="partnership-card" key={index}>
                                <img src={logo.imageUrl} alt={logo.name} />
                            </div>
                        ))}
                    </div>
                    <div className="pagination-controls">
                        <button onClick={handlePrev} disabled={currentIndex === 0}>Prev</button>
                        <button onClick={handleNext} disabled={currentIndex + itemsPerPage >= partnershipLogos.length}>Next</button>
                    </div>
                </div>
            </section>*/}

            {/* Our Founders Section */}
            <div className="founders-section">
        <h2>Our Founders</h2>
        <div className="founders-container">
          <div className="glassmorphic-card founder-card" style={{ backgroundColor: '#5c3c7b' }}>
            <img src={founder1} alt="Deepak Kalkute" style={{
    height: 'auto', maxWidth: '100%', maxHeight: '40vh', objectFit: 'cover', 
  }}/>
        
            <p style={{ color: '#ffd105', display: 'block', marginTop: '0.5rem', marginLeft:'25px' }}>
            <b><b>Deepak Kalkute</b></b><br/>
              <b>Civil Engineer & Real Estate Consultant  <br/></b>
              Deepak Kalkute is a highly skilled civil engineer with a strong foundation in construction principles and urban development. With 5 years of hands-on experience as a real estate consultant, he has established himself as an expert in identifying valuable properties, understanding market trends, and providing strategic advice to clients. Deepak's expertise spans residential, commercial, and mixed-use developments, and his commitment to delivering client-centric solutions has earned him a trusted reputation in the industry. Passionate about shaping the future of real estate, he combines technical know-how with a deep understanding of market dynamics to help clients make informed investments.            </p>
          </div>
          <div className="glassmorphic-card founder-card" style={{ backgroundColor: '#5c3c7b' }}>
            
  <img 
    src={founder2} 
    alt="Pradeep Dhavan" 
    style={{
      height: 'auto', maxWidth: '100%', maxHeight: '40vh', objectFit: 'cover', 
    }}
  />
  
  <p style={{ color: '#ffd105', display: 'block', marginTop: '0.5rem', marginLeft:'25px' }}>
  <b><b>Pradeep Dhavan</b></b><br/>
    <b>Ex-Army Professional & Real Estate Consultant<br/></b>
    Pradeep Dhavan, an ex-army professional, brings a disciplined, strategic, and detail-oriented approach to the real estate industry. With 5 years of experience as a real estate consultant, he has developed a keen understanding of market trends, property valuations, and investment opportunities. His military background instilled in him the values of integrity, leadership, and perseverance, which he applies to every client interaction.
    Pradeep specializes in residential and commercial property transactions, offering clients well-rounded guidance and solutions tailored to their needs. His unique combination of discipline from the armed forces and expertise in real estate makes him a trusted advisor in the industry. Dedicated to helping clients achieve their property goals, Pradeep is known for his commitment to excellence and his ability to navigate the complexities of the market confidently.
  </p>
</div>

        </div>
      </div>

        </>
    );
};

export default BlogSection;
