// EnquiryForm.js
import React, { useState } from 'react';
import './EnquiryForm.css';
import 'font-awesome/css/font-awesome.min.css';

const EnquiryForm = ({ closeModal }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        location: '',
        configuration: '',
        investmentPlan: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        // Submit the form data to Formspree
        fetch('https://formspree.io/f/xwpkpayn', { // Replace YOUR_FORM_ID with your actual Formspree ID
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...formData,
                // Add any additional fields here if needed
            }),
        })
        .then((response) => {
            if (response.ok) {
                alert('Message sent successfully!');
                closeModal();
            } else {
                alert('An error occurred. Please try again.');
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        });
    };

    return (
        <div className="enquiry-form-overlay">
            <div className="enquiry-form-container">
                <button className="close-btn" onClick={closeModal} aria-label="Close">
                    <i className="fa fa-times" aria-hidden="true"></i>
                </button>
                <h2>Enquire Now</h2>
                <form onSubmit={sendEmail}>
                    <label>
                        Name:
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Email:
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Phone Number:
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Location:
                        <input
                            type="text"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Configuration:
                        <select
                            name="configuration"
                            value={formData.configuration}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Configuration</option>
                            <option value="2 BHK">2 BHK</option>
                            <option value="3 BHK">3 BHK</option>
                            <option value="4 BHK">4 BHK</option>
                            <option value="5 BHK">5 BHK</option>
                        </select>
                    </label>
                    <label>
                        Investment Plan:
                        <select
                            name="investmentPlan"
                            value={formData.investmentPlan}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Investment Plan</option>
                            <option value="40 Lakh">40 Lakh</option>
                            <option value="50 Lakh">50 Lakh</option>
                            <option value="60 Lakh">60 Lakh</option>
                            <option value="70 Lakh">70 Lakh</option>
                            <option value="80 Lakh">80 Lakh</option>
                            <option value="90 Lakh">90 Lakh</option>
                            <option value="1 Cr">1 Cr</option>
                            <option value="1.5 Cr">1.5 Cr</option>
                            <option value="2 Cr">2 Cr</option>
                            <option value="2.5 Cr">2.5 Cr</option>
                            <option value="3 Cr">3 Cr</option>
                        </select>
                    </label>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default EnquiryForm;
