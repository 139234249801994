// components/MetaPixel.js

import React, { useEffect } from 'react';

// Declare fbq as a global variable to avoid ESLint errors
/* global fbq */

const MetaPixel = () => {
    useEffect(() => {
        // Load the Meta Pixel script
        (function(f,b,e,v,n,t,s) {
            if (f.fbq) return;
            n = f.fbq = function() {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js'));
        
        // Initialize and track the page view
        fbq('init', '459749763759317'); // Replace with your Pixel ID
        fbq('track', 'PageView'); // Track page views
    }, []);

    return null; // This component doesn't render anything to the DOM
};

export default MetaPixel;
