import React, { useState, useEffect } from 'react';
import './ContactUs.css'; // Import your CSS file for styling
import { FaEnvelope, FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa'; // Importing icons

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const sendFormData = (e) => {
        e.preventDefault();

        // Submit the form data to Formspree
        fetch('https://formspree.io/f/mjkvkjll', { // Replace YOUR_FORM_ID with your actual Formspree ID
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...formData,
            }),
        })
        .then((response) => {
            if (response.ok) {
                alert('Message sent successfully!');
                // Reset the form
                setFormData({ name: '', email: '', phone: '' });
            } else {
                alert('An error occurred. Please try again.');
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        });
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className="contact-us-section">
            <div className="contact-us-hero">
                <div className="hero-overlay">
                    <h1 className="hero-title">Contact Us</h1>
                    <p className="hero-subtitle">Let's hear from you to discuss!</p>
                </div>
            </div>

            <div className="container">
                <div className="glassmorphic-card" style={{ backgroundColor: '#ffd105', width:'100%' }}>
                    <h2>Get In Touch</h2>
                    <p>
                        We’re here to assist you with all your real estate needs. Whether you're looking for your dream home, a profitable investment, or professional guidance in selling your property, The Pune Properties is just a call or message away.<br/><br/>
                        Feel free to get in touch with us for personalized advice, market insights, or any questions you may have. We’re committed to providing prompt and professional service every step of the way.
                        <br/><br/>
                    </p>
                    <form className="contact-form" onSubmit={sendFormData}>
                        <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="tel"
                            name="phone"
                            placeholder="Your Contact Number"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                        <button type="submit">Submit</button>
                    </form>
                </div>

                <div className="glassmorphic-card contact-details" style={{ backgroundColor: '#ffd105' }}>
                    <h2>Contact Information</h2>
                    <p >
                        <FaEnvelope className="contact-icon" /> <strong>Email:</strong> thepuneproperties2024@gmail.com
                    </p>
                    <p>
                        <FaPhoneAlt className="contact-icon" /> <strong>Phone:</strong> 844-623-1017
                    </p>
                    <p>
                        <FaMapMarkerAlt className="contact-icon" /> <strong>Address:</strong> The Pune Properties
                        26/19, Shambhu Vihar Society, Balaji Park, Baner, Pune, Maharashtra 411007
                    </p>

                    {/* Google Maps iframe */}
                    <div className="map-container">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.461898375295!2d73.80036547496378!3d18.55320338254668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf377a7b0aed%3A0x21b693dbccaab498!2sThe%20Pune%20Properties!5e0!3m2!1sen!2sin!4v1729193184821!5m2!1sen!2sin"
                            width="100%" height="350" frameBorder="0" style={{ border: 0 }} allowFullScreen="" loading="lazy" title="Google Map"
                        ></iframe>
                    </div>
                    <p>
                        <strong>Business Hours:</strong> 
                        Tuesday- Sunday: 10:00 AM – 7:00 PM<br/>
                        Monday: Closed
                    </p>
                    <p>
                        <strong>Let's Connect:</strong> 
                        <div className="social-icons">
                            <a href="https://www.facebook.com/profile.php?id=61566541717340" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                            <a href="https://www.linkedin.com/company/104809129/admin/dashboard/" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                            <a href="https://www.instagram.com/thepuneproperties/" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-instagram"></i>
                            </a>
                        </div>
                    </p>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
